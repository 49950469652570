import React from "react";
import "../Styles/LandingMainPage.css";
import points from "../Assets/Arrow.svg";
import darkPoints from "../Assets/Landing-Dark-Arrow.svg";

import Grid2 from "@mui/material/Grid2";
import "../Assets/landing-main-bg.png";
import meet from "../Assets/meet-bg.png";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import logo from "../Assets/Landing-Grid-Logo.svg";

const LandingMainPage = (props) => {
  const pointsData = [
    "In an industry hungry for speed, security, and simplicity, myAdvisorGrids catapults agency commission grid systems into an exciting new frontier.",
    "Rather than just organizing data, MAG’s next-generation architecture sculpts it digging deep into datasets from every corner of the enterprise and synthesizing precise, real - time visuals.",
    "From empowering BGA's to oversee intricate commission calculations in a single click, to giving regional managers dynamic dashboards that update on the fly, MAG ushers in an era of instant, no - nonsense commission grid transparency.",
  ];

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  return (
    <>
      <Grid2 container className="lp-main-page-container">
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar component="nav" className="landing-app-bar">
            <Toolbar className="landing-app-toolbar">
              <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={handleDrawerToggle}
                sx={{ padding: "0px", display: { sm: "none", xs: "none" }, color: "black" }}>
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" component="div" className="lp-head-section-item"
                sx={{
                  flexGrow: 1,
                  display: { xs: "block", sm: "block" },
                }}
              >
                <img src={logo} alt="myAdvisorGrids" className="landing-logo" />
              </Typography>
            </Toolbar>
          </AppBar>
          <nav>
            <Drawer variant="temporary" open={mobileOpen} onClose={handleDrawerToggle} ModalProps={{ keepMounted: true }}
              sx={{
                display: { xs: "none", sm: "none" },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: 240,
                },
              }}
            ></Drawer>
          </nav>
          <Box component="main">
            <Toolbar />
          </Box>
        </Box>
        <Grid2 size={{ xs: 12 }}>
          <Grid2 container>
            <Grid2 size={{ xs: 12 }} className="lp-landing-hero-section-item">
              <Grid2
                container
                sx={{
                  height: "100%",
                  alignItems: "center",
                }}
                className="hero-child"
              >
                <Grid2 size={{ xs: 12 }} className="lp-head-section-item">
                  <Grid2 container>
                    <Grid2 className="lp-point-header-item">
                      <Grid2 container>
                        <Grid2 size={{ xs: 12 }} sx={{ marginBottom: { sm: "30px", xs: "20px", md: "40px" } }}>
                          <Typography variant="span" className="lp-head-text">
                            myAdvisorGrids - A Glimpse into the Future of
                            Intelligent Commission Grid
                          </Typography>
                        </Grid2>
                        <div className="forGap">
                          {pointsData.map((ele) => {
                            return (
                              <>
                                <Grid2 size={{ xs: 12 }} className="lp-sub-points-item">
                                  <Grid2 container sx={{ alignItems: "start", justifyContent: "space-between" }}>
                                    <Grid2 className="lp-point-item">
                                      <img src={points} alt="" srcset="" style={{ width: "18px", height: "auto", verticalAlign: "middle" }} />
                                    </Grid2>
                                    <Grid2 className="lp-text-item">
                                      <Typography variant="span">
                                        {ele}
                                      </Typography>
                                    </Grid2>
                                  </Grid2>
                                </Grid2>
                              </>
                            );
                          })}
                        </div>
                      </Grid2>
                    </Grid2>
                  </Grid2>
                </Grid2>
              </Grid2>
            </Grid2>

            <Grid2 container className="parent-meet">
              <Grid2 size={{ xs: 12 }} className="lp-meets-section-item">
                <Grid2 container className="lp-meets-section-container">
                  <Grid2 size={{ xl: 5.8, lg: 5.8, md: 12, sm: 12, xs: 12 }} className="lp-meet-img-item">
                    <img src={meet} className="lp-meet-img" alt="" srcset="" />
                  </Grid2>
                  <Grid2 size={{ xl: 5.5, lg: 5.5, md: 12, sm: 12, xs: 12 }} className="testing">
                    <Grid2 container>
                      <Grid2 size={{ xs: 12 }} className="lp-meet-head-item">
                        <Typography variant="span">
                          Where Tomorrow Meets Results Today
                        </Typography>
                      </Grid2>
                      <Grid2 size={{ xs: 12 }} className="lp-sub-points-item">
                        <Grid2 container sx={{ alignItems: "start", justifyContent: "space-between" }}>
                          <Grid2 className="lp-point-item">
                            <img src={darkPoints} alt="" style={{ width: "18px", height: "auto", verticalAlign: "middle" }} />
                          </Grid2>
                          <Grid2 className="lp-text-meet-item">
                            <Typography variant="p">
                              myAdvisorGrids isn’t just a tool; it’s a
                              comprehensive, future-proof communication
                              infrastructure.
                            </Typography>
                          </Grid2>
                        </Grid2>
                      </Grid2>
                      <Grid2 size={{ xs: 12 }} className="lp-sub-points-item">
                        <Grid2 container sx={{ alignItems: "start", justifyContent: "space-between", }}>
                          <Grid2 className="lp-point-item">
                            <img src={darkPoints} alt="" style={{ width: "18px", height: "auto", verticalAlign: "middle", }} />
                          </Grid2>
                          <Grid2 className="lp-text-meet-item">
                            <Typography variant="p">
                              By staying edgy and forward-looking. tapping into
                              AI and a robust forecasting engine, MAG empowers
                              financial agencies to handle the growing
                              complexity of multi- faceted commission grid
                              arrangements with accuracy and style.
                            </Typography>
                          </Grid2>
                        </Grid2>
                      </Grid2>
                      <Grid2 size={{ xs: 12 }} className="lp-sub-points-item">
                        <Grid2 container sx={{ alignItems: "start", justifyContent: "space-between", }}>
                          <Grid2 className="lp-point-item">
                            <img src={darkPoints} alt="" style={{ width: "18px", height: "auto", verticalAlign: "middle", }} />
                          </Grid2>
                          <Grid2 className="lp-text-meet-item">
                            <Typography variant="p">
                              Welcome to a place where data is not just tamed,
                              but elevated, so you can focus on the business
                              itself instead of the chaos behind the scenes.
                              With MAG, the once- difficult puzzle of large -
                              scale commission grid management becomes a fluid,
                              collaborative experience. As we say around here:
                            </Typography>
                            <Typography className="lp-meet-text" component="p">
                              Why settle for chasing yesterday’s numbers when
                              you can sculpt tomorrow’s success, right here,
                              right now?
                            </Typography>
                          </Grid2>
                        </Grid2>
                      </Grid2>
                    </Grid2>
                  </Grid2>
                </Grid2>
              </Grid2>
            </Grid2>
          </Grid2>
        </Grid2>
      </Grid2>
    </>
  );
};

export default LandingMainPage;
