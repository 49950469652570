import './App.css';
import { BrowserRouter } from "react-router-dom";
import { Fragment } from 'react';
import RoutePage from './Routes/RoutePage';

function App() {
  return (
    <Fragment>
      <BrowserRouter>
        <RoutePage />
      </BrowserRouter>
    </Fragment>
  );
}

export default App;
